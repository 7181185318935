import { XF } from "./XF";

export function pushToGTM(event) {
  const data = {
    event,
  };
  const text = window.parent.document.querySelector(
    ".quick-reply__sign-up-button"
  )?.textContent;
  if (text) {
    data.text = text;
  }
  window.parent.dataLayer.push(data);
}

export function validateUsername(username) {
  if (!username) {
    disableUsernameUpdateButton();
    return;
  }

  const request = new XMLHttpRequest();
  request.open(
    "GET",
    `/register/validate-username?_xfResponseType=json&_xfToken=${XF.config.csrf}&username=${username}`
  );

  request.onreadystatechange = function onReadyStateChange() {
    if (this.readyState === 4) {
      const response = JSON.parse(this.response);
      const input = document.getElementById("one-tap-prompt__username-input");
      const button = document.getElementById(
        "one-tap-prompt__username-confirm-button"
      );
      if (response.status === "ok" && response.message === "Succeeded") {
        document.querySelector(
          ".one-tap-prompt__username-input-hint--available"
        ).style.display = "block";
        document.querySelector(
          ".one-tap-prompt__username-input-hint--invalid"
        ).style.display = "none";
        input.classList.remove("one-tap-prompt__username-input--invalid");
        input.dataset.isTaken = false;
        button.classList.remove("one-tap-prompt__button--disabled");
        button.disabled = false;
      } else {
        disableUsernameUpdateButton(response.message);
      }

      const iframe = getOneTapIframeElement();
      iframe.height =
        document.getElementById("one-tap-prompt__confirm-username")
          .offsetHeight + 8;
    }
  };
  request.send();
}

export function disableUsernameUpdateButton(message) {
  const input = document.getElementById("one-tap-prompt__username-input");
  const button = document.getElementById(
    "one-tap-prompt__username-confirm-button"
  );
  document.querySelector(
    ".one-tap-prompt__username-input-hint--available"
  ).style.display = "none";
  const usernameInvalidElement = document.querySelector(
    ".one-tap-prompt__username-input-hint--invalid"
  );
  usernameInvalidElement.style.display = "block";
  usernameInvalidElement.innerHTML =
    message || usernameInvalidElement.innerHTML;

  input.classList.add("one-tap-prompt__username-input--invalid");
  input.dataset.isTaken = true;
  button.classList.add("one-tap-prompt__button--disabled");
  button.disabled = true;

  const iframe = getOneTapIframeElement();
  iframe.height =
    document.getElementById("one-tap-prompt__confirm-username").offsetHeight +
    8;
}

export function getOneTapIframeElement() {
  return window.frameElement;
}
