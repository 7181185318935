import { XF } from "./XF";
import { pushToGTM, validateUsername } from "./one-tap-functions";

document
  .querySelector(".quick-reply__sign-up-button")
  ?.addEventListener("click", openSignupModal);

export function openSignupModal(e: Event) {
  e.preventDefault();

  const formData = e.currentTarget
    ? $(e.currentTarget.closest("form")).serialize()
    : $(".js-quickReply").serialize();
  const endpoint = e.target?.href;

  const request = new XMLHttpRequest();
  request.open(
    "POST",
    `${endpoint}?_xfResponseType=json&_xfToken=${XF.config.csrf}`
  );
  request.setRequestHeader(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  request.onreadystatechange = function onReadyStateChange() {
    if (this.readyState === 4) {
      const response = JSON.parse(this.response);
      let overlay = null;
      if (response.status === "ok") {
        // push an event to GTM
        pushToGTM("GuestReplyStarted");

        let html = response.html.content;

        XF.Loader.load(response.html.js, response.html.css, () => {
          html = html.replace(
            /(<noscript>[\s\S]*?<\/noscript>)/gi,
            "<!-- $1 -->"
          );
          overlay = XF.getOverlayHtml({
            html,
          });

          // add class to the overlay so that the quick reply specific styles can be applied
          overlay.addClass("quick-reply__overlay");

          // remove the existing overlay that's closed already if there is any
          $(".quick-reply__overlay")?.remove();

          // display the overlay
          XF.showOverlay(overlay);

          // avoid iframe get loaded 3 times
          const quickReplyIframeElement = document.getElementById(
            "google-one-tap-iframe-quick-reply"
          );
          if (quickReplyIframeElement) {
            quickReplyIframeElement.src = "/login/onetap?guest_reply=1";
          }
          $("#google-one-tap-iframe").hide();

          const registrationForm = document.querySelector(
            ".quick-reply__registration-form"
          );
          if (registrationForm) {
            registrationForm.addEventListener("submit", () => {
              // push an event to GTM
              pushToGTM("GuestEmailSignup");
            });
          }

          switchSignInSignUpViews();
        });
      } else {
        // there is some error in the response display the overlay error message
        overlay = XF.getOverlayHtml({
          html: response.errorHtml.content,
        });

        XF.showOverlay(overlay);
      }
    }
  };

  request.send(formData);
}

// load overlay for connecting google (not one-tap)
document.addEventListener("DOMContentLoaded", () => {
  const guestReplyMethod = document.querySelector(
    "form.js-quickReply input[name=guestReplyMethod]"
  )?.value;

  if (guestReplyMethod) {
    // ajax call actionQuickRegistrationModal and load the box if from guestreply
    const formData = $(".js-quickReply").serialize();
    const endpoint = "/register/guest-reply-connect-account-modal";

    const request = new XMLHttpRequest();
    request.open(
      "POST",
      `${endpoint}?guestReplyMethod=${guestReplyMethod}&_xfResponseType=json&_xfToken=${XF.config.csrf}`
    );
    request.setRequestHeader(
      "Content-Type",
      "application/x-www-form-urlencoded; charset=UTF-8"
    );

    request.onreadystatechange = function onReadyStateChange() {
      if (this.readyState === 4) {
        const response = JSON.parse(this.response);
        let overlay = null;
        if (response.status === "ok") {
          let html = response.html.content;

          html = html.replace(
            /(<noscript>[\s\S]*?<\/noscript>)/gi,
            "<!-- $1 -->"
          );

          overlay = XF.getOverlayHtml({
            html,
          });

          // add class to the overlay so that the quick reply specific styles can be applied
          overlay.addClass("quick-reply__overlay");

          // remove the existing overlay that's closed already if there is any
          $(".quick-reply__overlay")?.remove();

          XF.Loader.load(response.html.js, response.html.css, () => {
            document
              .querySelector(".california-quick-reply-container")
              ?.scrollIntoView({ block: "start" });

            XF.showOverlay(overlay);

            document
              .querySelector(".one-tap__guest-reply form")
              .addEventListener("submit", () => {
                pushToGTM("GuestGoogleSignup");
              });

            const input = document.getElementById(
              "one-tap-prompt__username-input"
            );

            // listen for username change events
            let timeout = null;
            input?.addEventListener("keyup", () => {
              clearTimeout(timeout);
              timeout = setTimeout(() => {
                validateUsername(input.value);
              }, 300);
            });
          });
        } else {
          // there is some error in the response display the overlay error message
          overlay = XF.getOverlayHtml({
            html: response.errorHtml.content,
          });

          XF.showOverlay(overlay);
        }
      }
    };

    request.send(formData);
  }
});

function switchSignInSignUpViews() {
  document
    .querySelector(".quick-reply__sign-up-switch")
    ?.addEventListener("click", (e) => {
      e.preventDefault();
      Array.prototype.forEach.call(
        document.querySelectorAll("[class*=quick-reply__login-]"),
        (item) => {
          item.classList.add("hidden");
        }
      );
      Array.prototype.forEach.call(
        document.querySelectorAll("[class*=quick-reply__registration-]"),
        (item) => {
          item.classList.remove("hidden");
        }
      );
    });

  document
    .querySelector(".quick-reply__sign-in-switch")
    ?.addEventListener("click", (e) => {
      e.preventDefault();
      Array.prototype.forEach.call(
        document.querySelectorAll("[class*=quick-reply__login-]"),
        (item) => {
          item.classList.remove("hidden");
        }
      );
      Array.prototype.forEach.call(
        document.querySelectorAll("[class*=quick-reply__registration-]"),
        (item) => {
          item.classList.add("hidden");
        }
      );
    });
}

window.addEventListener(
  "message",
  (event) => {
    if (event.origin !== window.location.origin) {
      return;
    }

    if (event.data === "GoogleOneTapGuestReply") {
      document
        .querySelector("#google-one-tap-iframe-quick-reply")
        .closest(".overlay-container")
        .classList.add("one-tap-iframe-container");

      document
        .querySelector("#google-one-tap-iframe-quick-reply")
        .closest(".overlay-container")
        .querySelector(".separator").style.display = "none";
    } else if (event.data === "GoogleOneTapClose") {
      XF.hideParentOverlay($("#google-one-tap-iframe-quick-reply"));
    }
  },
  false
);
